<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-col class="row">
      <v-card
        v-if="ready"
        elevation="2"
        class="card-content">
        <h3 class="title-1 font-weight-bold py-4">
          Order Form ID : {{ prototype.order.orderId }}
        </h3>
        <v-col class="box">
          <h4 class="product-name subtitle-1 font-weight-bold">
            Detail
          </h4>
          <v-col class="box">
            Sketch
            <v-row>
              <v-col
                md="7"
                cols="12"
                style="display: flex; flex-wrap: wrap;">
                <div
                  v-for="(img, index) in prototype.sketch.images"
                  :key="`sketchImg-${index}`"
                  style="width: 50%; padding: 10px;">
                  <img :src="img">
                </div>
              </v-col>
              <v-col
                md="5"
                cols="12">
                <div> <b>Sketch ID : </b> {{ sketchId }} </div>
                <div> <b>Sketch Date : </b> {{ prototype.sketch.createdAt | dateTimeFormat() }} </div>
                <div> <b>Type : </b> {{ categoryName }} </div>
                <div> <b>Designer : </b> {{ designerName }} </div>
                <div> <b>Merchandizer : </b> {{ merchandiserName }} </div>
                <div> <b>Note : </b> {{ sketchNote }} </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="box"
            style="margin-top: -1px;">
            Fitting
            <v-row v-if="prototype.order.fitting && prototype.order.fitting !== null">
              <v-col
                md="7"
                cols="12"
                style="display: flex; flex-wrap: wrap;">
                <div
                  v-for="(img, index) in prototype.order.fitting.images"
                  :key="`fitting-${index}`"
                  style="width: 50%; padding: 10px;">
                  <img :src="img">
                </div>
              </v-col>
              <v-col
                md="5"
                cols="12">
                {{ prototype.order.fitting.note }}
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="box"
            style="margin-top: -1px;">
            Materails Detail
            <v-row
              v-for="(material, index) in prototype.sketch.materials"
              :key="`material-${index}`">
              <v-col
                md="7"
                cols="12"
                style="display: flex; flex-wrap: wrap;">
                <div
                  v-for="(img, i) in material.images"
                  :key="`material-img-${index}-${i}`"
                  style="width: 50%; padding: 10px;">
                  <img :src="img">
                </div>
              </v-col>
              <v-col
                md="5"
                cols="12">
                <div> <b>Quantity : </b> {{ material.quantity }} </div>
                <div> <b>Note : </b> {{ material.note }} </div>
              </v-col>
              <v-col
                v-if="prototype.sketch.materials && (index !== prototype.sketch.materials.length - 1)"
                cols="12">
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <v-col class="row">
          <v-col
            v-for="(sku, index) in mappedSkus"
            :key="`sku-${index}`"
            cols="12"
            class="box">
            <h2 class="font-weight-bold">
              {{ index + 1 }}. Product Mapping
            </h2>
            <div class="product-header">
              <span><b>Color : </b>{{ sku.color }}</span>
              <v-spacer />
              <search-box
                v-model="sku.queryOptions.search"
                :disabled="loading"
                @on-search="searchProductSellsuki(index)" />
            </div>
            <div class="product-content">
              <v-data-table
                :headers="headerProducts"
                :items="sku.productSellsuki"
                disable-sort
                disable-pagination
                hide-default-footer
                :loading="loading"
                class="elevation-1">
                <template v-slot:[`item.productSrc`]="{ item }">
                  <div
                    v-if="item && item.photoUrls.length > 0"
                    class="pa-2">
                    <img
                      :src="item.photoUrls[0]"
                      :alt="item.photoUrls[0]"
                      width="40">
                  </div>
                </template>
                <template v-slot:[`item.select`]="{ item }">
                  <v-icon
                    small
                    class="mr-2 cs-pointer"
                    @click="selectProduct(item, index)">
                    mdi-arrow-down-bold-box-outline
                  </v-icon>
                </template>
              </v-data-table>
              <classic-paginate
                class="mt-4"
                :page="sku.queryOptions.page"
                :per-page="sku.queryOptions.perPage"
                :is-load-success="sku.queryOptions.loadSuccess"
                :count="0"
                @on-page-change="changePage(index, $event)" />
            </div>
            <div>
              <div class="mer-product-header py-4">
                <v-divider class="my-4" />
                <div>
                  <b>OMS Product Code : </b>
                  {{ sku.selectProductSellsuki.id ? sku.selectProductSellsuki.name : '-' }}
                </div>
                <div>
                  <b>OMS Product Name : </b>
                  {{ sku.selectProductSellsuki.id ? getProductCode(sku.selectProductSellsuki.name) : '-' }}
                </div>
                <div>
                  <b>OMS Product ID : </b>
                  {{ sku.selectProductSellsuki.id ? sku.selectProductSellsuki.id : '-' }}
                </div>
              </div>
              <div class="mer-product-content">
                <v-data-table
                  :headers="headerPrototypes"
                  :items="sku.skus"
                  :disable-pagination="true"
                  :hide-default-footer="true"
                  :loading="loading"
                  class="elevation-1">
                  <template v-slot:[`item.selectSku`]="{ item }">
                    <v-select
                      v-if="!item.productSellsuki && item.productSellsuki === null"
                      v-model="item.productSellsuki"
                      :loading="loading"
                      :items="filterItem(sku.selectProductSellsuki.id ? sku.selectProductSellsuki.skus : [], sku.skus)"
                      return-object>
                      <template v-slot:item="selectData">
                        {{ selectData.item.code }} - {{ selectData.item.size.name }} | {{ selectData.item.color.name }}
                      </template>
                    </v-select>
                    <div
                      v-else
                      class="d-flex align-center">
                      <v-text-field
                        :value="displaySkuCodeAndOption(item)"
                        style="width: 80%"
                        disabled />
                      <gw-icon-hover
                        class="remove-img"
                        icon-name="mdi-delete"
                        icon-hover="mdi-delete-empty"
                        @click="item.productSellsuki = null" />
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-col>
        </v-col>
        <v-col class="row">
          <v-col class="text-center">
            <v-btn
              @click="save">
              <span class="body-2">
                <v-icon>mdi-content-save</v-icon>
                save
              </span>
            </v-btn>
          </v-col>
        </v-col>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import PrototypeProviderOld from '@/resources/PrototypeProviderOld'
import ProductProvider from '@/resources/ProductProvider'
import ClassicPaginate from '@/components/ClassicPaginate'
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'

const PrototypeService = new PrototypeProviderOld()
const ProductService = new ProductProvider()
export default {
  components: {
    ClassicPaginate,
    SearchBox
  },
  data () {
    return {
      getting: false,
      loading: false,
      ready: false,
      prototype: {},
      headerProducts: [
        {
          text: 'Img',
          value: 'productSrc',
          width: 100,
          sortable: false
        },
        {
          text: 'Id',
          value: 'id',
          width: 100,
          sortable: false
        },
        {
          text: 'Model',
          value: 'model',
          width: 100,
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          width: 400,
          sortable: false
        },
        {
          text: 'Select',
          value: 'select',
          width: 50,
          align: 'end',
          sortable: false
        }
      ],
      headerPrototypes: [
        {
          text: 'Color',
          value: 'color',
          width: 100,
          sortable: false
        },
        {
          text: 'Size',
          value: 'size',
          width: 100,
          sortable: false
        },
        {
          text: 'Quantity',
          value: 'quantity',
          width: 100,
          sortable: false
        },
        {
          text: 'Cost(THB)',
          value: 'cost',
          width: 100,
          sortable: false
        },
        {
          text: 'Select',
          value: 'selectSku',
          width: 100,
          sortable: false
        }
      ],
      mappedSkus: [],
      selectedProduct: null
    }
  },
  computed: {
    prototypeId () {
      return this.$route.params.id
    },
    sketchId () {
      return (this.ready ? this.prototype?.sketch?.sketchId || ' - ' : '')
    },
    categoryName () {
      return (this.ready ? this.prototype?.sketch?.category?.name || ' - ' : '')
    },
    designerName () {
      return (this.ready ? this.prototype?.sketch?.designer?.name || ' - ' : '')
    },
    merchandiserName () {
      return (this.ready ? this.prototype?.sketch?.merchandiser?.name || ' - ' : '')
    },
    sketchNote () {
      return (this.ready ? this.prototype?.sketch?.note || ' - ' : '')
    }
  },
  created () {
    this.getPrototype()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    displaySkuCodeAndOption (item) {
      return `${item.productSellsuki.code} - ${item.productSellsuki.size.name
        || item.productSellsuki.size} | ${item.productSellsuki.color.name || item.productSellsuki.color}`
    },
    save () {
      const results = []
      let isMapped = true
      this.mappedSkus.forEach((skus) => {
        const payload = skus.skus.map((sku) => {
          const result = {
            ...sku,
            productSellsuki: sku.productSellsuki === null ? null : {
              productId: sku.productSellsuki.productId ? sku.productSellsuki.productId
                : skus.selectProductSellsuki.id,
              skuId: sku.productSellsuki.skuId ? sku.productSellsuki.skuId
                : sku.productSellsuki.id,
              name: sku.productSellsuki.name ? sku.productSellsuki.name
                : skus.selectProductSellsuki.name,
              color: sku.productSellsuki.color ? sku.productSellsuki.color.name
                : this.getSkuOptions('color', skus.selectProductSellsuki, sku.productSellsuki),
              size: sku.productSellsuki.size ? sku.productSellsuki.size.name
                : this.getSkuOptions('size', skus.selectProductSellsuki, sku.productSellsuki),
              option: sku.productSellsuki.size ? sku.productSellsuki.option
                : this.getSkuOptions('option', skus.selectProductSellsuki, sku.productSellsuki),
              mappedDate: sku.productSellsuki.mappedDate ? sku.productSellsuki.mappedDate
                : new Date(),
              code: sku.productSellsuki.code,
              photos: sku.productSellsuki.photos ? sku.productSellsuki.photos
                : skus.selectProductSellsuki.photos
            }
          }
          if (result.productSellsuki === null) {
            isMapped = false
          }
          return result
        })
        results.push(...payload)
      })
      this.mapProduct({
        productId: this.selectedProduct?.model || null,
        productSkus: results,
        isMapped,
        id: this.prototype.id })
    },
    async mapProduct (payload) {
      try {
        this.getting = true
        this.loading = true

        const { data } = await PrototypeService.mapProduct(payload)
        if (data) {
          this.$router.push({ name: 'ProductMappingListOld' })
        }
        this.setSnackbar({
          value: true,
          message: 'Success',
          type: 'success'
        })
      } catch (error) {
        console.error('get: ', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.getting = false
        this.ready = true
      }
    },
    getSkuOptions (field, product, sku) {
      switch (field) {
        case 'color':
          if (product.variant1 === 'Color' || product.variant1 === 'color') {
            return sku.option1
          } if (product.variant2 === 'Color' || product.variant2 === 'color') {
            return sku.option2
          }
          return null

        case 'size':
          if (product.variant1 === 'Size' || product.variant1 === 'size') {
            return sku.option1
          } if (product.variant2 === 'Size' || product.variant2 === 'size') {
            return sku.option2
          }
          return null

        default:
          return sku.option3
      }
    },
    selectProduct (product, index) {
      this.selectedProduct = product
      this.mappedSkus[index].selectProductSellsuki = this.mappedSkus[index].productSellsuki.find((pd) => pd.id === product.id)
    },
    getProductCode (name) {
      const arrayName = name.split('-')
      return arrayName[arrayName.length - 1]
    },
    filterItem (allItems, selectedItems) {
      return allItems.map((al) => ({
          ...al,
          disabled: selectedItems.findIndex(
            (se) => se.productSellsuki !== null
            && (se.productSellsuki.skuId === al.id || se.productSellsuki.id === al.id)
          ) !== -1
        }))
    },
    async searchProductSellsuki (index) {
      this.mappedSkus[index].productSellsuki = []
      this.mappedSkus[index].queryOptions.page = 1
      this.mappedSkus[index].queryOptions.loadSuccess = false
      this.getProductSellsuki(index, this.mappedSkus[index].queryOptions)
    },
    changePage (index, page) {
      if (this.mappedSkus[index].queryOptions.page > page) {
        this.mappedSkus[index].queryOptions.loadSuccess = false
      } else if (this.mappedSkus[index].queryOptions.loadSuccess || this.mappedSkus[index].queryOptions.page === page) {
        return
      }
      this.mappedSkus[index].queryOptions.page = page
      this.getProductSellsuki(index, this.mappedSkus[index].queryOptions)
    },
    async getProductSellsuki (index, _query) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const query = {
            search: _query.search,
            itemsPerPage: _query.perPage,
            page: _query.page,
            sortBy: [_query.sortBy],
            sortDesc: [_query.sortOrder]
          }
          const { data: { results } } = await ProductService.getProductsForMapping(query)
          this.mappedSkus[index].productSellsuki = results
        } catch (error) {
          console.error('get: ', error)
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    async getPrototype () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await PrototypeService.getOneSketchById(this.prototypeId)
          this.prototype = data
          this.mappingProduct()
        } catch (error) {
          console.error('get: ', error)
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
        } finally {
          this.loading = false
          this.getting = false
          this.ready = true
        }
      }
    },
    mappingProduct () {
      let colors = this.prototype.order.productSkus.map((product) => product.color)
      colors = [...new Set(colors)]
      for (const color of colors) {
        const result = {
          color,
          skus: this.prototype.order.productSkus.filter((pd) => pd.color === color),
          productSellsuki: [],
          queryOptions: {
            search: '',
            page: 1,
            perPage: 5,
            loadSuccess: true,
            warehouse: 'sellsuki',
            sortBy: 'updatedAt',
            sortOrder: 'DESC'
          },
          selectProductSellsuki: {}
        }
        this.mappedSkus.push(result)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.card-content {
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 15px;
}
.product-header {
  display: flex;
  padding: 8px;
}
.box {
  border: 1px solid #cacaca;
}
</style>
